@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/Roboto-Light.woff2") format("woff2"), url("assets/fonts/Roboto-Light.woff") format("woff"),
    url("assets/fonts/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/Roboto-Bold.woff2") format("woff2"), url("assets/fonts/Roboto-Bold.woff") format("woff"),
    url("assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/Roboto-Black.woff2") format("woff2"), url("assets/fonts/Roboto-Black.woff") format("woff"),
    url("assets/fonts/Roboto-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/Roboto-Regular.woff2") format("woff2"), url("assets/fonts/Roboto-Regular.woff") format("woff"),
    url("assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/Roboto-Medium.woff2") format("woff2"), url("assets/fonts/Roboto-Medium.woff") format("woff"),
    url("assets/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/Roboto-Thin.woff2") format("woff2"), url("assets/fonts/Roboto-Thin.woff") format("woff"),
    url("assets/fonts/Roboto-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.discover {
  background-color: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

div .MuiFormHelperText-root {
  color: red;
}

html {
  height: 100%;
}

body,
#root,
.App {
  height: 100%;
  min-height: 100%;
}
