.App {
  font-size: calc(10px + 2vmin);
}
.App label {
  font-size: 1rem;
}

h2 {
  font-size: 24px;
  letter-spacing: -0.2px;
  color: #000000;
  line-height: 24px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 auto;
}

h3 {
  font-size: 18px;
  letter-spacing: -0.2px;
  color: #000000;
  line-height: 18px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 auto;
}

h4 {
  font-size: 16px;
  letter-spacing: -0.2px;
  color: #000000;
  line-height: 16px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 auto;
}

h5 {
  font-size: 14px;
  letter-spacing: -0.2px;
  color: #000000;
  line-height: 14px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 auto;
}

h6 {
  font-size: 12px;
  letter-spacing: -0.2px;
  color: #000000;
  line-height: 12px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 auto;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.hidden {
  display: none !important;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  display: none;
}

#dialog-title {
  text-align: center;
  padding: 24px 24px;
}
#dialog-title h2 {
  font-size: 17px;
  line-height: 20px;
  font-weight: 700;
}
div[aria-labelledby="dialog-title"] {
  border-radius: 15px;
}
#dialog-title + .MuiDialogContent-dividers {
  border: none;
  padding: 0px 16px 12px 16px;
  font-size: 1rem;
}
#dialog-title + .MuiDialogContent-dividers h6 {
  display: none;
}
#dialog-title + .MuiDialogContent-dividers + .MuiDialogActions-spacing {
  display: flex;
  flex-direction: column-reverse;
  padding-left: 16px;
  padding-right: 16px;
}
#dialog-title + .MuiDialogContent-dividers + .MuiDialogActions-spacing > :not(:first-child) {
  margin-left: 0px;
}
#dialog-title + .MuiDialogContent-dividers + .MuiDialogActions-spacing button:first-child {
  color: #898989;
  text-transform: capitalize;
}
#dialog-title + .MuiDialogContent-dividers + .MuiDialogActions-spacing button:nth-child(2) {
  width: 100%;
  border-radius: 50px;
  background-color: #33776b;
  margin-bottom: 12px;
  height: 48px;
  text-transform: capitalize;
}

#pwaUpdateModal .MuiPaper-root {
  border-radius: 15px;
}
#pwaUpdateModal .MuiDialogContent-root {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
#pwaUpdateModal .modal-title {
  font-size: 20px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 20px;
}
#pwaUpdateModal .desc {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 25px;
}
#pwaUpdateModal .desc .bold-700 {
  font-weight: 700;
}
#pwaUpdateModal .pwaUpdateOk {
  color: #33776b;
  padding: 10px;
  font-weight: bold;
  text-transform: capitalize;
}
